<template>
  <div class="btn-group" :class="{'opacity-button': !isMe}">
    <button class="btn btnoutline dropdown-toggle" type="button" id="dropdownMenuClickableInside" data-bs-toggle="dropdown" aria-expanded="false">
    {{ selected && selected.length ? sltSubCategoryString : $t("search-tab.type-of-activity") }}
    </button>

    <select-sub-category type="dropdown" propsClassName="dropdown-menu durationflightdropdown dropdown-menu-end" aria-labelledby="dropdownMenuClickableInside"
     :options="options"
     :category="category"
     />

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import dropdownOpenState from '@/sabre/common/mixins/dropdownOpenState';

export default {
  name: 'sub-category-dropdown',
  mixins: [dropdownOpenState],
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    category: {
      type: Object,
      default: null,
    },
  },
  components: {
    selectSubCategory: () => import('./FHSelectSubCate'),
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      selected: 'GET_FH_SELECTED_SUB_CATE',
      subCategoryList: 'GET_FH_SUB_CATEGORY_LIST',
    }),
    sltSubCategoryString() {
      const { selected, subCategoryList, lang } = this;
      if (!selected || !selected.length) return '';

      const sltCount = selected.length;
      if (sltCount > 1) return `${sltCount} selected`;

      const firstItem = subCategoryList.find((sub) => sub.id === selected[0]);
      return firstItem ? firstItem[`name${lang.charAt(0).toUpperCase() + lang.slice(1)}`] : '';
    },
  },
};
</script>
